import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { EnvironmentService } from '@app/core/environment-service';

/**
 * Гвард для определения доступности модуля для загрузки
 * Проверка идет по environment.MODULES (по аналогии с директивой IsModuleOn)
 * Если на путь навешан этот гвард, но в модулях его нет, то редиректит на главную страницу
 */
@Injectable({
  providedIn: 'root',
})
export class ModuleOnGuard {
  readonly #router = inject(Router);
  readonly #environmentService = inject(EnvironmentService);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // Дробим путь на сегменты ['transaction','withdrawal']
    const fullPathSegments = route.pathFromRoot.flatMap((r) => r.url.map((segment) => segment.path));
    const filteredPathSegments = fullPathSegments.filter((segment) => segment);
    const environment = this.#environmentService.environments;

    if (this.#isPathValid(filteredPathSegments, environment.MODULES)) {
      return true;
    } else {
      this.#router.navigate(['/']);
      return false;
    }
  }

  #isPathValid(segments: string[], envObject: any): boolean {
    if (!segments.length) {
      return true;
    }

    const currentSegment = segments[0];
    // по той же вложенности, что и сегменты спускаемся в environment.MODULES
    if (currentSegment in envObject) {
      // Если в модулях есть такой объект, то true
      return this.#isPathValid(segments.slice(1), envObject[currentSegment]);
    } else {
      return false;
    }
  }
}
