import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class OnlyServerGuard {
  constructor(
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isServer) {
      return true;
    } else {
      return this.router.navigate(['not-found'], { replaceUrl: true });
    }
  }
}
