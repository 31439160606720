import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IUserDetailed } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { UserState } from '@app/core/state/user-store';

@Injectable({
  providedIn: 'root',
})
export class BanActivateGuard {
  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed>;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig && route.routeConfig.path ? route.routeConfig.path : '';

    const allowedPath = ['support', 'account', 'block'];
    return this.user$.pipe(
      map((value) => {
        if (!!value && value.permLock) {
          if (allowedPath.some((el) => path.includes(el))) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
    );
  }
}
