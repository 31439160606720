import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';

@Injectable({
  providedIn: 'root',
})
export class GamesGuard {
  readonly #router = inject(Router);
  readonly #environmentService = inject(EnvironmentService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig && route.routeConfig.path ? route.routeConfig.path : '';
    const hasModule = (value: string): boolean | undefined => this.#environmentService.getEnvironment().MODULES.games?.includes(value);
    return hasModule(path) ? true : this.#router.navigate(['/'], { replaceUrl: true });
  }
}
