import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Select } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';

import { NotificationsService } from '@app/core/notification-service';
import { GamesState } from '@app/core/state/games-store';

@Injectable({
  providedIn: 'root',
})
export class AutoGameDeactivateGuard {
  @Select(GamesState.autoBetIsOn)
  readonly autoBetIsOn$!: Observable<boolean>;

  constructor(private readonly notificationsService: NotificationsService) {}
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.autoBetIsOn$.pipe(
      tap((value) => {
        if (value) {
          this.notificationsService.addInfoNotification('You need to stop auto betting first.');
        }
      }),
      map((value) => !value),
    );
  }
}
